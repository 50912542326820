import { useState, useEffect } from 'react';
import api from '../lib/api';

export default function useSponsors() {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    api.get('sponsors/premier', { signal: controller.signal })
      .then((data) => setSponsors(data))
      .catch(() => { });

    return () => {
      controller.abort();
    };
  }, []);

  return sponsors;
}
