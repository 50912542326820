/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import UAParser from 'ua-parser-js';
import {
  Container, Divider, Icon, Form, Button, Segment, Message,
} from 'semantic-ui-react';
import classNames from 'classnames';
import api from '../lib/api';
import styles from './Feedback.module.scss';

export default function Feedback({ user }) {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [saving, setSaving] = useState(false);
  const [finished, setFinished] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [updater, setUpdater] = useState(user.id);
  // eslint-disable-next-line no-unused-vars
  const [notes, setNotes] = useState('');

  const onSave = () => {
    const ua = new UAParser(navigator.userAgent);
    const { type, model, vendor } = ua.getDevice();
    const { name: browserName, version: browserVersion } = ua.getBrowser();
    const { name: osName, version: osVersion } = ua.getOS();
    setSaving(true);
    const loc = window.location;
    const url = `${loc.pathname}${loc.search}${loc.hash}`;
    api
      .post('user/feedback', {
        title,
        user_id: updater,
        body,
        url,
        device_type: type,
        device_model: model,
        device_vendor: vendor,
        browser_name: browserName,
        browser_version: browserVersion,
        os: osName,
        os_version: osVersion,
        updated_by: updater,
        notes,
      })
      .then(() => setFinished(true))
      .catch(() => setSaving(false));
  };

  // reset the form when closed
  useEffect(() => {
    if (!expanded) {
      setBody('');
      setTitle('');
      setFinished(false);
    }
  }, [expanded]);

  useEffect(() => {
    if (finished) {
      setSaving(false);
      setTimeout(() => {
        setExpanded(false);
      }, 3000);
    }
  }, [finished]);

  if (!user || !user.role_id || user.role_id < 4) {
    return null;
  }

  const ui = finished ? (
    <Message positive>
      <Message.Header>Thank you!</Message.Header>
      <p>Your feedback was successfully shared.</p>
    </Message>
  ) : (
    <Segment loading={saving}>
      <Message attached="top">
        <Message.Header>
          <Icon name="comment outline" />
          Tell us what you think.
        </Message.Header>
      </Message>
      <Divider />
      <Form>
        <Form.Field>
          <label htmlFor="title">Title</label>
          <Form.Input
            type="text"
            id="title"
            placeholder="What&rsquo;s missing/broken?"
            onChange={(e, { value }) => setTitle(value)}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="body">Feedback</label>
          <Form.TextArea
            rows={10}
            name="body"
            onChange={(e, { value }) => setBody(value)}
            placeholder="Please type your feedback here, thank you!"
          />
        </Form.Field>
        <Form.Field>
          <Container textAlign="right">
            <Button basic onClick={() => setExpanded(false)}>
              Cancel
            </Button>
            <Button color="teal" onClick={onSave}>
              Submit Feedback
            </Button>
          </Container>
        </Form.Field>
      </Form>
    </Segment>
  );

  const trigger = (
    <Button className={styles.open} onClick={() => setExpanded(true)} size="large" color="teal">
      <Icon name="comment outline" /> Feedback
    </Button>
  );

  return (
    <div className={classNames(styles.container, { [styles.open]: expanded })}>
      {expanded ? ui : trigger}
    </div>
  );
}
