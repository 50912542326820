import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Form,
  Grid,
  Header,
  Image,
  Input,
  Message,
  Segment,
} from 'semantic-ui-react';
import Link from 'next/link';
import api from '../lib/api';
import styles from './LoginForm.module.scss';

const Logo = '/images/LANA_LOGO_padded.png';
const LoginForm = ({
  inModal = false,
  noHeader = false,
  // eslint-disable-next-line
  onAuthenticated = () => console.warn('onAuthenticated not supplied'),
}) => {
  const [username, setUser] = useState(null);
  const [password, setPass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState();

  const doLogin = () => {
    setLoading(true);
    api
      .post('user/login', { username, password })
      .then((record) => {
        if (record?.error) {
          return setError(record.error);
        }
        onAuthenticated(record);
      })
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  const onRecover = () => {
    api.post('user/recover', { email }).then(() => setSent(true));
  };

  const container = (children) => (
    <Grid
      textAlign="center"
      style={inModal ? { height: '100vh' } : { height: '100%' }}
      verticalAlign="middle"
    >
      {children}
    </Grid>
  );

  return container(
    <>
      <Grid.Column style={inModal ? { maxWidth: 450 } : {}}>
        {noHeader === false && (
          <Header color="teal">
            <Grid columns={2} stretched>
              <Grid.Column>
                <Image src={Logo} size="small" />
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Header.Content as="h3" color="teal" textAlign="center">
                  Log-in to your account
                </Header.Content>
              </Grid.Column>
            </Grid>
          </Header>
        )}
        {sent && (
          <Message positive className={styles.message}>
            <Message.Header>Password Reset Request sent!</Message.Header>
            <p>An email has been sent. If you do not receive the email within 10 minutes, please check your junk mail or spam folders. If you do not receive any email in response, feel free to <Link href="/contact">Contact Us.</Link></p>
          </Message>
        )}
        {error && <Message negative title="Login failed" content={error} />}
        <Form size="large">
          <Segment stacked>
            <Dimmer active={loading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <Form.Input
              fluid
              icon="user"
              autoFocus
              disabled={loading}
              iconPosition="left"
              placeholder="Username"
              onChange={(e, { value }) => setUser(value)}
            />
            <Form.Input
              fluid
              icon="lock"
              disabled={loading}
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e, { value }) => setPass(value)}
            />

            <Button color="teal" fluid size="large" onClick={doLogin}>
              Login
            </Button>
          </Segment>
        </Form>
        <Message>
          <strong>First Time User? Forgot your username or password?</strong>
          <br />
          <p>
            To reset your password, enter your <strong>email address</strong>. If you do not
            remember the email address associated with your account, you may enter your{' '}
            <strong>LANA Certification Number</strong> and we will send an email to the address we
            currently have on file.
          </p>
          <Input
            value={email}
            onChange={(e, { value }) => setEmail(value)}
            placeholder="Enter Email Address"
          />
          <Button onClick={onRecover} color="teal">
            Submit
          </Button>
          <br />
          <br />
          If you need additional assistance or you do not have a valid email address on file,
          <Link href="/contact"> Contact Us </Link>to request login credentials.
        </Message>
      </Grid.Column>
    </>,
  );
};

export default LoginForm;
