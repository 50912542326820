import axios from 'axios';

export default class api {
  static get baseURL() {
    let baseURL = '';
    if (!process.browser) {
      baseURL = `http://${process.env.HOST}:${process.env.PORT}`;
    }
    return baseURL;
  }

  static async get(path, options = {}) {
    const res = await axios.get(`${this.baseURL}/api/${path}`, options);
    return res.data;
  }

  static async* stream(path, data) {
    const response = await fetch(`${this.baseURL}/api/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
      },
      body: JSON.stringify(data),
      responseType: 'stream',
    });

    // eslint-disable-next-line no-restricted-syntax
    for await (const chunk of response.body) {
      yield new TextDecoder().decode(chunk);
    }
  }

  static async post(path, data) {
    const res = await axios.post(`${this.baseURL}/api/${path}`, data);
    return res.data;
  }

  static login(username, password, nonce = null) {
    return this.post('user/login', { username, password, nonce });
  }

  static sponsors() {
    return this.get('sponsors/all');
  }

  static board() {
    return this.get('board/all');
  }
}

// dynamically generate single (e.g. api/${type}/[id].jsx)
// and all endpoints (e.g. api/${type}/all)
const endpoints = [
  ['quiz', 'quizzes'],
  ['page', 'pages'],
  ['job', 'jobs'],
  ['event', 'events'],
  ['therapist', 'therapists'],
];

endpoints.forEach(([single, all]) => {
  api[single] = (id) => api.get(`${single}/${id}`);
  api[all] = () => api.get(`${single}/all`);
});
