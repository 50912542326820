import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Button, Container } from 'semantic-ui-react';
import Sponsors from './Sponsors';
import useSponsors from '../hooks/useSponsors';

const FluidLayout = ({
  children, className, noButton = false, noSponsors = false,
}) => {
  const router = useRouter();
  const sponsors = useSponsors();

  const hero = (
    <Container id="splashToo" textAlign="center" fluid>
      {noButton === false && (
        <Button
          id="findSpecialist"
          icon="search"
          size="huge"
          color="orange"
          content="Find a CLT-LANA Certified Specialist"
          style={{
            marginTop: '120px',
            padding: '20px',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
          }}
          onClick={() => router.push('/therapists')}
        />
      )}
    </Container>
  );

  return (
    <Container fluid className={classNames(className, 'FluidLayout RedesignHome')}>
      <Container fluid id="pageContent">
        {hero}
        {children}
      </Container>
      {noSponsors === false && <Sponsors sponsors={sponsors} isHomePage />}
    </Container>
  );
};

export default FluidLayout;

/** @type {import("next").Metadata} */
export const metadata = {
  title: {
    template: '%s | LANA',
    default: 'Lymphology Association of North America',
  },
  description: 'The Lymphology Association of North America (LANA) is a non-profit corporation composed of healthcare professionals, including physicians, nurses, massage therapists, physical therapists, and occupational therapists experienced in the field of Lymphology and lymphedema. They have acknowledged the need for a national certification examination for lymphedema therapists, to test knowledge considered fundamental in the treatment of lymphedema.',
  keywords: [],
  metadataBase: new URL('https://clt-lana.org'),
  alternates: {
    canonical: '/',
  },
};
