const delegatedAdmins = ['erin.fazzari'];
const expandedAdmins = [...delegatedAdmins, 'linda.menzies', 'deborah.gross'];

const permissionOverrides = {
  '/api/board_app/votes': {
    get: delegatedAdmins,
  },
  '/api/board_app/edit/:id': {
    post: delegatedAdmins,
  },
  '/api/board_app/delete/:id': {
    post: delegatedAdmins,
  },
  '/api/board_app/id/:id': {
    post: delegatedAdmins,
    get: expandedAdmins,
  },
  '/admin/board_app': {
    get: expandedAdmins,
  },
  '/admin/board_app/[id]': {
    get: expandedAdmins,
    post: delegatedAdmins,
  },
  '/api/board_app/all': {
    get: expandedAdmins,
  },
};

function hasSpecialPermission(method, path, username) {
  const overrides = permissionOverrides[path] && permissionOverrides[path][method.toLowerCase()];
  if (overrides && overrides.includes(username)) {
    return true;
  }
  return false;
}

module.exports = hasSpecialPermission;
