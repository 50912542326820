const DEFAULT_SECONDS = 3600;
export default class Cache {
  static set(key, value, ttlSeconds = DEFAULT_SECONDS) {
    const record = {
      expires: Date.now() + (ttlSeconds * 1000),
      value,
    };
    window.localStorage.setItem(key, JSON.stringify(record));
  }

  static get(key) {
    const value = window.localStorage.getItem(key);
    if (value) {
      const record = JSON.parse(value);
      if (record.expires < Date.now()) {
        window.localStorage.removeItem(key);
      } else {
        return record.value;
      }
    }
  }
}
