/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';
import striptags from 'striptags';

const titlePrefix = 'LANA';
const defaultTitle = 'Lymphology Association of North America';
const defaultDescription = 'The Lymphology Association of North America (LANA) is a non-profit corporation composed of healthcare professionals, including physicians, nurses, massage therapists, physical therapists, and occupational therapists experienced in the field of Lymphology and lymphedema. They have acknowledged the need for a national certification examination for lymphedema therapists, to test knowledge considered fundamental in the treatment of lymphedema.';

const keywords = [
  'lana',
  'clt lana',
  'best lymphedema treatment',
  'lymphedema',
  'lymphology association',
  'lymphology association of north america',
  'lympedema',
  'lymphedema treatment',
  'lymphedema certification exam',
  'lymphedema specialists',
  'lymphedema support groups',
  'lymphedema doctor',
  'lymphedema training',
  'lymphedema therapy',
  'lymphedema certification',
  'lymphedema therapists',
  'lymphology',
  'certified lana',
  'clt-lana',
  'lana certification',
  'lana exam',
  'lana website',
  'certified lymphedema therapists',
  'lana testing',
  'lana resources',
  'lana board of directors',
];

export default function PageMeta({ title = defaultTitle, description = defaultDescription }) {
  return (
    <Head>
      {title && <meta key="siteTitle" property="og:title" name="title" content={title} />}
      {description && (
        <meta
          key="siteDescription"
          property="og:description"
          name="description"
          content={striptags(description)}
        />
      )}
      <title key="doctitle">{`${titlePrefix} | ${title}`}</title>
      <meta name="keywords" content={keywords.join(',')} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
    </Head>
  );
}
