import { Message, Icon } from 'semantic-ui-react';
import LoginForm from './LogInForm';

export default function NotAuthorized({ loggedIn = false }) {
  const onAuthenticated = () => {
    window.location.reload();
  };

  return (
    <div>
      <Message negative={loggedIn} warning={!loggedIn}>
        <Message.Header as="h1">
          <Icon name="warning" />
          {' '}
          {loggedIn ? 'Not authorized.' : 'Members-only content.'}
        </Message.Header>
        {loggedIn ? (
          <p>Your account is not authorized to view this content.</p>
        ) : (
          <p>Sorry, you must log in to access this content.</p>
        )}
        {!loggedIn && <LoginForm noHeader inModal={false} onAuthenticated={onAuthenticated} />}
      </Message>
    </div>
  );
}
