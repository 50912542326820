import { useState, useEffect } from 'react';
import {
  Container, Divider, Grid, Placeholder,
} from 'semantic-ui-react';
import styles from './Loader.module.scss';

export default function Loader() {
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    setIsSSR(false);
  }, []);

  if (isSSR) return null;
  return (
    <div className={styles.Loader}>
      <Container>
        <Grid columns={2}>
          <Grid.Column width={3}>
            <Divider hidden />
            <Divider hidden />
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={13}>
            <br />
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line length="medium" />
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
            <Divider />
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Grid.Column>
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
        </Grid>
      </Container>
    </div>
  );
}
