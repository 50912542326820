import classNames from 'classnames';
import styles from './SiteAlert.module.scss';

export default function SiteAlert({ preamble = false, message }) {
  if (!message) return null;
  return (
    <div className={classNames(styles.SiteAlert, 'ui warning')}>
      {preamble && <strong>{preamble}</strong>} <span>{message}</span>
    </div>
  );
}
