import { Message } from 'semantic-ui-react';

export default function ErrorMessage({
  title = "We're sorry, an error has occurred.",
  message = 'Unknown error',
}) {
  return (
    <Message negative>
      <Message.Header as="h1">{title}</Message.Header>
      <p>{message}</p>
    </Message>
  );
}
